import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../styles/colors';
import { signOut } from '../../actions/sign-in';
import { connect } from 'react-redux';
import { history } from '../../routers/AppRouter';
import MainButton from '../../components/MainButton';

const StyledSidebar = styled.div`
  height: 100%;
  background: ${colors.lightestGray};
  height: 100vh;
  padding: 40px 20px;
  width: 200px;
  a {
    color: ${colors.blue};
    text-decoration: none;
    padding: 5px 0;
  }
  display: flex;
  flex-direction: column;
`;

const ButtonPadding = styled.div`
  padding: 60px 0;
`;

const Divider = styled.div`
  height: 1px;
  background: ${colors.gray200};
  margin: 5px 0;
`;

const SidebarHeading = styled.div`
  font-size: 19px;
  font-weight: bold;
  padding: 10px 0 5px 0;
`;

const Sidebar = (props) => {

  const onSignout = () => {
    props.signOut();
    history.push('/sign-in');
  };

  return (
    <StyledSidebar>
      <NavLink to="/alerts" activeClassName="is-active">Alerts</NavLink>
      <NavLink to="/posts" activeClassName="is-active">Posts</NavLink>
      <NavLink to="/career-center" activeClassName="is-active">Career Center</NavLink>
      <NavLink to="/events" activeClassName="is-active">Events</NavLink>
      <NavLink to="/microsites" activeClassName="is-active">Microsites</NavLink>
      <NavLink to="/contact-forms" activeClassName="is-active">Contact Forms</NavLink>
      <NavLink to="/ad-manager" activeClassName="is-active">Ad Manager</NavLink>
      <NavLink to="/media-library" activeClassName="is-active">Media Library</NavLink>
      <NavLink to="/aspen-alert" activeClassName="is-active">Aspen Alert</NavLink>
      <NavLink to="/user-list" activeClassName="is-active">Users</NavLink>
      <NavLink to="/client-list" activeClassName="is-active">Clients</NavLink>
      <NavLink to="/stats-export" activeClassName="is-active">Stats Export</NavLink>
      {/* <NavLink to="/annex" activeClassName="is-active">Annex</NavLink> */}
      <Divider />
      <SidebarHeading>Portal</SidebarHeading>
      <NavLink to="/portal/email-performance" activeClassName="is-active">Email Performance</NavLink>
      <ButtonPadding>
        <MainButton onClick={onSignout} title="Sign Out" />
      </ButtonPadding>
    </StyledSidebar>
  );
};

const mapStateToProps = (state) => ({
  // isAuthenticated: !!state.userInfo.token,
  // profile: state.userInfo.profile
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
