import React from "react";
import styled from 'styled-components';

interface Props {
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
    disabled?: boolean;
}

const StyledInput = styled.input`
    padding: 10px;
    font-size: 16px;
    width: 100%;
    min-width: 400px;
    margin-bottom: 20px;
    line-height: 25px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
`;

const ABInputField: React.FC<Props> = ({ value, onChange, placeholder, disabled }) => {
    return (
        <StyledInput
            type="text"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
        />
    );
}

export default ABInputField;