import axiosInstance from '../../utility/axios';
import { POST_TYPE_NAME } from '../../utility/constants';

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});

export const startEmailContentStat = () => ({
    type: 'START_GET_EMAIL_CONTENT_STAT',
});

export const getEmailContentStatSuccess = (emailContentStat) => ({
    type: 'GET_EMAIL_CONTENT_STAT_SUCCESS',
    emailContentStat
});

export const getEmailContentStat = (id) => {
    return (dispatch) => {
        dispatch(startEmailContentStat());
        axiosInstance.get(`email-content-stat/${id}`)
            .then((response) => {
                if (response.data.emailContentStat === false) { // no post yet
                } else { // has post
                    console.log('response.data: ', response.data);
                    dispatch(getEmailContentStatSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startGetEmailContentStats = () => ({
    type: 'START_GET_EMAIL_CONTENT_STATS',
});

export const getEmailContentStatsSuccess = (emailContentStats) => ({
    type: 'GET_EMAIL_CONTENT_STATS_SUCCESS',
    emailContentStats
});

// get all email content stats by emailStatId
export const getEmailContentStats = (emailStatId, queryParams) => {
    return (dispatch) => {
        let url = `email-content-stats/${emailStatId}`;
        if (queryParams) {
            url = `email-content-stats/${emailStatId}/${queryParams}`;
        }
        console.log('get email content stats url: ', url);
        dispatch(startGetEmailContentStats());
        axiosInstance.get(url)
            .then((response) => {
                console.log('email-content-stats/emailsStatsId: ', response.data);
                dispatch(getEmailContentStatsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startCreateEmailContentStat = () => ({
    type: 'START_CREATE_EMAIL_CONTENT_STAT'
});

export const createEmailContentStatSuccess = (emailContentStat) => ({
    type: 'CREATE_EMAIL_CONTENT_STAT_SUCCESS',
    emailContentStat
});

export const createEmailContentStat = (emailContentStat) => {
    console.log('creating email content stat', emailContentStat);
    return (dispatch) => {
        dispatch(startCreateEmailContentStat());
        axiosInstance.post('email-content-stat', emailContentStat)
            .then((response) => {
                dispatch(createEmailContentStatSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError());
                console.log(error);
            });
    }
};

export const startEmailContentStatUpdate = () => ({
    type: 'START_EMAIL_CONTENT_STAT_UPDATE',
});

export const updateJobSuccess = (emailContentStat) => ({
    type: 'UPDATE_EMAIL_CONTENT_STAT_SUCCESS',
    emailContentStat
});

export const updateJob = (emailContentStat, emailContentStatId) => {
    return (dispatch) => {
        dispatch(startEmailContentStatUpdate());
        axiosInstance.put(`email-content-stat/${emailContentStatId}`, emailContentStat)
            .then((response) => {
                dispatch(updateJobSuccess(emailContentStat));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startRemoveEmailContentStat = () => ({
    type: 'START_REMOVE_EMAIL_CONTENT_STAT'
});

export const removeEmailContentStatSuccess = (emailContentStatIds) => ({
    type: 'REMOVE_EMAIL_CONTENT_STAT_SUCCESS',
    emailContentStatIds
});

export const removeEmailContentStat = (emailContentStatIds) => {
    return (dispatch) => {
        const items = {
            itemsToDelete: emailContentStatIds
        };
        dispatch(startRemoveEmailContentStat());
        axiosInstance.delete('statsEmails', { data: items })
            .then((response) => {
                dispatch(removeEmailContentStatSuccess(emailContentStatIds));
                console.log('statsEmails removed!');
            })
            .catch((error) => {
                dispatch(showError());
                console.log(error);
            });
    }
};

