import axiosInstance from "../../utility/axios";
import { showError } from "../ads";

export const startGetAlertItems = () => ({
    type: 'START_GET_ALERT_ITEMS',
});

export const getAlertItemsSuccess = (alerts) => ({
    type: 'GET_ALERT_ITEMS_SUCCESS',
    alerts
});

export const getAlertItems = (queryParams) => {
    return (dispatch) => {
        let url = 'alert-items';
        if (queryParams && queryParams.status) {
            url = `alert-items?status=${queryParams.status}`;
        }
        dispatch(startGetAlertItems());
        axiosInstance.get(url)
            .then((response) => {
                console.log('data response alerts: ', response.data);
                dispatch(getAlertItemsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

// export const startAlertItemCreate = () => ({
//     type: 'START_ALERT_CREATE',
// });

// export const createAlertItemSuccess = (alertDetails) => ({
//     type: 'CREATE_ALERT_SUCCESS',
//     alertDetails
// });

// export const createAlertItem = (alertDetails) => {
//     return (dispatch) => {
//         dispatch(startAlertItemCreate());
//         axiosInstance.post(`alert-item`, alertDetails)
//             .then((response) => {
//                 dispatch(createAlertItemSuccess(response.data));
//             })
//             .catch((error) => {
//                 dispatch(showError(error));
//                 console.log(error);
//             });
//     }
// };

// export const startAlertItemUpdate = () => ({
//     type: 'START_ALERT_ITEM_UPDATE',
// });

// export const updateAlertItemSuccess = (alertDetails) => ({
//     type: 'UPDATE_ALERT_ITEM_SUCCESS',
//     alertDetails
// });

// export const updateAlertItem = (alertDetails) => {
//     return (dispatch) => {
//         dispatch(startAlertItemUpdate());
//         axiosInstance.put(`alert-item/${alertDetails.id}`, alertDetails)
//             .then((response) => {
//                 dispatch(updateAlertItemSuccess(alertDetails));
//                 setTimeout(() => {
//                     dispatch(clearAlertItemSuccess());
//                 }, 3000)
//             })
//             .catch((error) => {
//                 dispatch(showError(error));
//                 console.log(error);
//             });
//     }
// };


// export const clearAlertItemSuccess = () => ({
//     type: 'ALERT_CLEAR_SUCCESS',
// });