import axiosInstance from '../../utility/axios';
import { POST_TYPE_NAME } from '../../utility/constants';

export const showError = (errorMessage) => ({
    type: 'SHOW_ERROR',
    errorMessage
});

export const startEmailStat = () => ({
    type: 'START_GET_EMAIL_STAT',
});

export const getEmailStatSuccess = (emailStat) => ({
    type: 'GET_EMAIL_STAT_SUCCESS',
    emailStat
});

export const getEmailStat = (id) => {
    return (dispatch) => {
        dispatch(startEmailStat());
        axiosInstance.get(`email-stat/${id}`)
            .then((response) => {
                if (response.data.job === false) { // no post yet
                } else { // has post
                    console.log('response.data in email stat: ', response.data);
                    dispatch(getEmailStatSuccess(response.data));
                }
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startGetEmailStats = () => ({
    type: 'START_GET_EMAIL_STATS',
});

export const getEmailStatsSuccess = (statsEmail) => ({
    type: 'GET_EMAIL_STATS_SUCCESS',
    statsEmail
});

export const getEmailStats = (queryParams) => {
    return (dispatch) => {
        let url = 'email-stats';
        if (queryParams) {
            url = `email-stats/${queryParams}`;
        }
        console.log('get stats-email url: ', url);
        dispatch(startGetEmailStats());
        axiosInstance.get(url)
            .then((response) => {
                dispatch(getEmailStatsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startCreateStatsEmail = () => ({
    type: 'START_CREATE_STATS_EMAIL'
});

export const createStatsEmailSuccess = (statsEmail) => ({
    type: 'CREATE_STATS_EMAIL_SUCCESS',
    statsEmail
});

export const createStatsEmail = (statsEmail) => {
    console.log('creating stats-email', statsEmail);
    return (dispatch) => {
        dispatch(startCreateStatsEmail());
        axiosInstance.post('email-stat', statsEmail)
            .then((response) => {
                dispatch(createStatsEmailSuccess(response.data));
            })
            .catch((error) => {
                dispatch(showError());
                console.log(error);
            });
    }
};

export const startJobUpdate = () => ({
    type: 'START_STATS_EMAIL_UPDATE',
});

export const updateJobSuccess = (jobDetails) => ({
    type: 'UPDATE_STATS_EMAIL_SUCCESS',
    jobDetails
});

export const updateJob = (jobDetails, jobId) => {
    return (dispatch) => {
        dispatch(startJobUpdate());
        axiosInstance.put(`job/${jobId}`, jobDetails)
            .then((response) => {
                dispatch(updateJobSuccess(jobDetails));
            })
            .catch((error) => {
                dispatch(showError(error));
                console.log(error);
            });
    }
};

export const startRemoveStatsEmails = () => ({
    type: 'START_REMOVE_STATS_EMAIL'
});

export const removeStatsEmailsSuccess = (jobIds) => ({
    type: 'REMOVE_STATS_EMAIL_SUCCESS',
    jobIds
});

export const removeStatsEmails = (jobIds) => {
    return (dispatch) => {
        const items = {
            itemsToDelete: jobIds
        };
        dispatch(startRemoveStatsEmails());
        axiosInstance.delete('statsEmails', { data: items })
            .then((response) => {
                dispatch(removeStatsEmailsSuccess(jobIds));
                console.log('statsEmails removed!');
            })
            .catch((error) => {
                dispatch(showError());
                console.log(error);
            });
    }
};

